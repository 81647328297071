<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="sing-up"
           style="background-image: url('img/singup-bg.jpg');">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
              <form action=""
                    id="singUp"
                    @submit.prevent="newPasswordForm">
                <h1>Change Password</h1>
                <div class="form-group has-icon">
                  <i class="icon-lock" />
                  <button type="button"
                          class="showPass"
                          @click="switchVisibility">
                    {{ user.passwordFieldType == 'password' ? 'Show' : 'Hide' }}
                  </button>
                  <input name="password"
                         v-model="user.password"
                         v-validate="'required'"
                         :type="user.passwordFieldType"
                         ref="password"
                         class="form-control input-lg"
                         placeholder="Password">
                </div>
                <span class="text-danger small mb-3 d-block fix-margin"
                      v-if="errors.has('password')">{{ errors.first('password') }}</span>
                <div class="form-group">
                  <input name="reset_code"
                         v-model="user.resetCode"
                         v-validate="'required'"
                         class="form-control input-lg"
                         placeholder="Reset Code">
                </div>
                <span class="text-danger small mb-3 d-block fix-margin"
                      v-if="errors.has('reset_code')">{{ errors.first('reset_code') }}</span>
                <span class="text-primary small mt-3 mb-3 d-block fix-margin"
                      v-if="getMessage">{{ getMessage }}</span>
                <button class="btn btn-primary btn-block btn-lg">
                  CHANGE PASSWORD
                </button>
                <br>
                <hr>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>
<script>

export default {
  name: 'NewPassword',
  components: {
  },
  data() {
    return {
      user: {
        password: null,
        passwordFieldType: 'password',
        resetCode: '',
      },
      message: '',
    };
  },
  computed: {
    getMessage() {
      return this.message;
    },
  },
  methods: {
    newPasswordForm() {
      this.$validator.validate().then((result) => {
        if (result) {
          const formData = new FormData();
          formData.append('password', this.user.password);
          formData.append('password_confirmation', this.user.password);
          formData.append('reset_code', this.user.resetCode);
          formData.append('_method', 'put');
          this.$axios.post('/v1/account/reset/password', formData).then((response) => {
            this.message = response.data.message;
          }).catch(() => {
            this.message = 'Something went wrong';
          });
        }
      });
    },
    switchVisibility() {
      this.user.passwordFieldType = this.user.passwordFieldType === 'password' ? 'text' : 'password';
    },
  },
  created() {
    this.$store.dispatch('updateSignInModalStatus', false);
    this.$store.dispatch('updateForgotPasswordModalStatus', false);
    this.user.resetCode = this.$route.query.reset_code;
  },
};
</script>

<style>
#app {
  min-height: 0px!important;
}
</style>
